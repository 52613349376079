<template>
  <b-modal id="profile-detail-modal" size="xl">
    <b-row class="match-height">
      <b-col md="6">
        <b-card no-body>
          <b-form-group class="p-1">
            <h3 id="deposit">
              ประวัติการฝาก
            </h3>
          </b-form-group>
          <b-table striped hover responsive show-empty class="position-relative items-center" :per-page="perPage"
            :current-page="currentPage" :items="deposits" :fields="depositFields" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
            :filter-included-fields="filterOn">
            <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>
            <template #empty="scope">
              <div class="p-2 text-center">
                <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
                  'ไม่พบข้อมูล' }}
              </div>
            </template>
            <template #cell(picture)="data">
              <b-avatar size="lg" :src="data.item.picture" />
            </template>
            <template #cell(ip)="data">
              <div class="text-nowrap">
                <a :href="`http://${data.item.ip}`" target="_blank">{{
                  data.item.ip
                }}</a>
              </div>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <feather-icon :id="`invoice-row-${data.item.id}-preview-icon`" icon="FileTextIcon" size="16"
                  class="mx-1" @click="
                    $router.push({
                      name: 'dashboard',
                      params: { id: data.item.id },
                    })
                    " />
                <b-tooltip title="History Deposit & Withdraw" :target="`invoice-row-${data.item.id}-preview-icon`" />
              </div>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1">
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
                prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
          <b-modal id="modal-1" title="Add Class" ok-only ok-title="Save">
            <b-form-group label="Class" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="CheckSquareIcon" />
                </b-input-group-prepend>
                <b-form-input id="vi-first-name" placeholder="" />
              </b-input-group>
            </b-form-group>
          </b-modal>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <b-form-group class="mr-1 mb-md-0 p-2">
            <h3 id="withdraw">
              รายการถอน
            </h3>
          </b-form-group>
          <b-table striped hover responsive show-empty class="position-relative items-center" :per-page="perPageWith"
            :current-page="currentPageWith" :items="withdraws" :fields="withdrawFields" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
            :filter-included-fields="filterOn">
            <template #cell(index)="data">
              {{ perPageWith * (currentPageWith - 1) + (data.index + 1) }}
            </template>
            <template #empty="scope">
              <div class="p-2 text-center">
                <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
                  'ไม่พบข้อมูล' }}
              </div>
            </template>
            <template #cell(picture)="data">
              <b-avatar size="lg" :src="data.item.picture" />
            </template>
            <template #cell(ip)="data">
              <div class="text-nowrap">
                <a :href="`http://${data.item.ip}`" target="_blank">{{
                  data.item.ip
                }}</a>
              </div>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <feather-icon :id="`invoice-row-${data.item.id}-preview-icon`" icon="FileTextIcon" size="16"
                  class="mx-1" @click="
                    $router.push({
                      name: 'dashboard',
                      params: { id: data.item.id },
                    })
                    " />
                <b-tooltip title="History Deposit &amp; Withdraw"
                  :target="`invoice-row-${data.item.id}-preview-icon`" />
              </div>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1">
              <b-form-select id="perPageSelect" v-model="perPageWith" size="sm" inline :options="pageOptions" />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination v-model="currentPageWith" :total-rows="totalRowsWith" :per-page="perPageWith" first-number
                last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
          <b-modal id="modal-1" title="Add Class" ok-only ok-title="Save">
            <b-form-group label="Class" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="CheckSquareIcon" />
                </b-input-group-prepend>
                <b-form-input id="vi-first-name" placeholder="" />
              </b-input-group>
            </b-form-group>
          </b-modal>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <div>
              <b-row>
                <b-col cols="12" md="4" class="
                        d-flex
                        align-items-center
                        justify-content-start
                        mb-1 mb-md-0
                      ">
                  <b-form-group class="mr-1 mb-md-0">
                    <h3 id="play">
                      รายการเล่นวันนี้
                    </h3>
                  </b-form-group>
                </b-col>
                <b-col md="3" />
                <b-col cols="12" md="4">
                  <b-form-group label="ช่วงเวลา" label-for="customDelimiter">
                    <flat-pickr v-model="rangeDate" class="form-control" :config="{
                      mode: 'range',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      time_24hr: true,
                      defaultHour: 0,
                    }" @change="getHistory" />
                  </b-form-group>
                </b-col>
                <b-col md="1" class="p-0" style="margin-top: 20px">
                  <b-form-group>
                    <b-button block variant="gradient-primary" @click="gethistory()">
                      ค้นหา
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
          <b-table striped hover responsive show-empty class="position-relative items-center" :per-page="perPageHis"
            :items="itemsGame" :fields="fieldsGame" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn">
            <template #cell(index)="data">
              {{ perPageHis * (currentPageHis - 1) + (data.index + 1) }}
            </template>
            <template #empty="scope">
              <div class="p-2 text-center">
                <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
                  'ไม่พบข้อมูล' }}
              </div>
            </template>
            <template #cell(picture)="data">
              <b-avatar size="lg" :src="data.item.picture" />
            </template>
            <template #cell(ip)="data">
              <div class="text-nowrap">
                <a :href="`http://${data.item.ip}`" target="_blank">{{
                  data.item.ip
                }}</a>
              </div>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div v-if="data.item.productId === 'PG'" class="text-nowrap">

                <button class="btn-show" @click="showAction(data.item), printConsoleToHtml">
                  รายละเอียด
                </button>

                <b-modal ref="my-action" hide-footer title="รายงานการเล่น">
                  <iframe frameborder="0" width="100%" height="600px" :src="gameResultUrl" size="lg"
                    title="รายละเอียด" />
                </b-modal>
              </div>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1">
              <b-form-select id="perPageSelect" v-model="perPageHis" size="sm" inline :options="pageOptions"
                @change="gethistory()" />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination v-model="currentPageHis" :total-rows="totalRowsGame" :per-page="perPageHis" first-number
                last-number prev-class="prev-item" next-class="next-item" class="mb-0" @input="gethistory()">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
          <b-modal id="modal-1" title="Add Class" ok-only ok-title="Save">
            <b-form-group label="Class" label-for="vi-first-name">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="CheckSquareIcon" />
                </b-input-group-prepend>
                <b-form-input id="vi-first-name" placeholder="" />
              </b-input-group>
            </b-form-group>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  // asdas
  // BInputGroupAppend,
  BFormCheckbox,
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'
// import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormCheckbox,
    BOverlay,
    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    // StatisticCardWithAreaChart,
    // VueApexCharts,
    BIconController,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: ['deposits', 'withdraws', 'username'],
  data: () => ({
    // deposits: [],
    // withdraw: [],
    depositFields: [
      { key: 'index', label: 'no.' },
      { key: 'bank', label: 'บัญชี' },
      { key: 'from', label: 'มาจาก' },
      // { key: 'username', label: 'ยูสเซอร์เนม' },
      { key: 'amount', label: 'จำนวน', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      { key: 'bonus', label: 'โบนัส', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      { key: 'balance', label: 'ยอดเงินคงเหลือ', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      { key: 'status', label: 'status' },
      { key: 'time', label: 'เวลาสลิป' },
      {
        key: 'created_at',
        label: 'เวลา',
        formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
      },
      // { key: 'actions', label: 'Actions' },
    ],
    withdrawFields: [
      { key: 'index', label: 'no.' },
      // { key: 'username', label: 'ยูสเซอร์เนม' },
      {
        key: 'amount',
        label: 'จำนวน',
        formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      },
      { key: 'balance', label: 'ยอดเงินคงเหลือ', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      { key: 'status', label: 'status' },
      { key: 'note', label: 'หมายเหตุ' },
      {
        key: 'created_at',
        label: 'เวลา',
        formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
      },
      // { key: 'actions', label: 'Actions' },
    ], 
    fieldsGame: [
      { key: 'index', label: 'no.' },
      { key: 'productId', label: 'ค่ายเกมส์' },
      // { key: 'appid', label: 'appid' },
      { key: 'betType', label: 'betType' },
      { key: 'balanceBefore', label: 'ยอดเงินก่อนแทง', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      { key: 'balance', label: 'ยอกเงินคงเหลือ', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      { key: 'betAmount', label: 'ยอดแทง', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      { key: 'winAmount', label: 'ยอดชนะ', formatter: value => Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
      {
        key: 'created_at',
        label: 'เวลา',
        formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
      },

      // {
      //   key: 'created_at',
      //   label: 'created at',
      //   formatter: value => moment(value)
      //     .tz('Asia/Bangkok')
      //     .format('DD/MM/YYYY HH:mm:ss'),
      // },
      { key: 'actions', label: 'Actions' },
    ],
    rangeDate: null,
    totalRowsGame: 1,
    itemsGame: [],
    perPage: 10,
    pageOptions: [10, 50, 100, 200, 500],
    totalRows: 1,
    currentPage: 1,
    currentPageWith: 1,
    totalRowsWith: 0,
    perPageWith: 10,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    perPageHis: 50,
    currentPageHis: 1,
  }),
  methods: {

    getHistory() {
      // console.log(this.rangeDate)
      const rangArray = this.rangeDate.split(' to ')
      // eslint-disable-next-line no-unused-vars
      const start = rangArray[0]
      // eslint-disable-next-line no-unused-vars
      const end = rangArray[1]
      // console.log(start)
      // console.log(end)
    },
    gethistory() {
      const rangArray = this.rangeDate.split(' to ')
      const start = rangArray[0]
      const end = rangArray[1]
      this.show = true
      const obj = {
        username: this.$props.username,
        start,
        end,
        page: this.currentPageHis,
        perPage: this.perPageHis,
      }
      this.$http
        .post('/history/play', obj)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.listdata = response.data.data
          this.onFilteredHis(response.data)
        })
        .catch(error => console.log(error))
    },
    onFilteredHis(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.itemsGame = filteredItems.data
      this.totalRowsGame = filteredItems.total
    },
  }
}
</script>